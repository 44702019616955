import { Routes } from '@angular/router';





export const routes: Routes = [
    { path: '',   redirectTo: '/map', pathMatch: 'full' },
    {
        path: 'map',
        loadComponent: () => import('./map/map.component').then(m => m.MapComponent),
    },
    {
        path: 'awos',
        loadComponent: () => import('./awos/awos.component').then(m => m.AwosComponent),
    },
    {
        path: 'alerts',
        loadComponent: () => import('./alerts/alerts.component').then(m => m.AlertsComponent),
    },
    {
        path: 'stations',
        loadComponent: () => import('./stations/stations.component').then(m => m.StationsComponent),
    },
];
