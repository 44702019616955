import { StationFormState } from "src/app/stations/station-dialog-form/station-dialog-form.component";
import { Parameter, Unit } from "./measurements.models";
import { ObjectValues } from './object-values.models';
import { MessageType } from "./rx-stomp.models";
export const STATUS = {
    DEGRADED: 'DEGRADED',
    NORMAL: 'NORMAL',
    MALFUNCTIONING: 'MALFUNCTIONING',
} as const;

export type Status = ObjectValues<typeof STATUS>;

export const SOURCE = {
    AWOS: 'AWOS',
    NAWOS: 'NAWOS',
    OTHER: 'OTHER'
} as const;
export type Source = ObjectValues<typeof SOURCE>;
export const ALL_SOURCES = Object.values(SOURCE);

export type StationId = string;

export type Station = {
    id: StationId,
    name: string;
    status: Status;
    longitude: number;
    latitude: number;
    lastUpdate: Date;
    events: Event[];
    media?: string;
    sensors: Sensor[];
    parameters: Parameter[];
    source: Source;
}

export type StationDTO = {
    id: StationId,
    name: string;
    status: Status;
    longitude: number;
    latitude: number; 
    source: Source;
}

export type StationParameter = {
    [key: StationId]: Parameter[], 
}

export type StationStatusDataStream = {
    type: MessageType,
    stationStatus: StationStatus, 
}

export type StationStatus = {
    [key: StationId]: Status, 
}


export const MODE = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
} as const;
export type ModeFormDialog = ObjectValues<typeof MODE>;

export type StationFormDataDialog = {
    station: StationFormState,
    mode: ModeFormDialog,
}


export const SENSOR = {
    WIND_SPEED: 'WIND_SPEED',
    WIND_SPEED_MS: 'WIND_SPEED_MS',
    WIND_DIRECTION: 'WIND_DIRECTION',
    CROSS_WIND_COMPONENT: 'WIND_CROSSWIND', 
    TRACK_WIND_COMPONENT: 'WIND_TRACKWIND', 
    PRECIPITATION: 'PRECIPITATION',
    RELATIVE_HUMIDITY: 'HUMIDITY',
    TEMPERATURE: 'TEMPERATURE',
    DEW_POINT: 'DEW_POINT',
    SOIL_MOISTURE: 'SOIL_MOISTURE',
    AERONAUTICAL_VISIBILITY: 'AERONAUTICAL_VISIBILITY',
    RUNWAY_VISUAL_RANGE: 'RVR',
    AIRPORT_LEVEL_PRESSURE: 'QFE',
    MEAN_SEA_LEVEL_PRESSURE: 'QNH',
    AIRPORT_LEVEL_PRESSURE_INCHES: 'QFE_INCHES',
    MEAN_SEA_LEVEL_PRESSURE_INCHES: 'QNH_INCHES',
    MOR: 'MOR',
    BGL: 'BGL',
    LOW_CLOUD_LAYER: 'CLOUD_LAYER_1',
    MIDDLE_CLOUD_LAYER: 'CLOUD_LAYER_2',
    UPPER_CLOUD_LAYER: 'CLOUD_LAYER_3',
    SOLAR_RADIATION: 'SOLAR_RADIATION',
    SOLAR_DURATION: 'SOLAR_DURATION',
    PRESENT_WEATHER: 'PRESENT_WEATHER',
};

export type Category = {
    code: string;
    label: string;
};

export type SensorType = ObjectValues<typeof SENSOR>;

export type Sensor = {
    type: SensorType;
    //TO DO Convert code type in enum
    unit: Unit;
    label?: string
    category: Category;
    icon: string;
}

export type Location = {
    city: string;
    country: Country;
    coordinates: GeolocationCoordinates;
}

export type GeolocationCoordinates = {
    latitude: number;
    longitude: number;
}

export type Country = {
    code: string;
    name: string;
}

export type Event = object;
