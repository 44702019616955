import { Measurement, StationMeasurement, UNIT, Unit } from "../models/measurements.models";
import { SENSOR, SensorType } from "../models/stations.models";
import { TimeSeriesItem } from "../models/time-series.models";

// Extend the Performance interface to include the memory property
interface PerformanceMemory {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
}

interface Performance {
  memory?: PerformanceMemory;
}

export function performanceLog() {
  const perf = performance as Performance
  if (perf.memory) {
    console.log("TEST_MEMORY_LEAK: Total Heap Size: " + new Intl.NumberFormat().format(perf.memory.totalJSHeapSize));    // Total allocated heap size
    console.log("TEST_MEMORY_LEAK : Used Heap Size: " + new Intl.NumberFormat().format(perf.memory.usedJSHeapSize));      // Currently used heap size
  } else {
    console.log("TEST_MEMORY_LEAK: Memory info not available in this browser.");
  }
}

const sensorTypeToRoundedConfiguration: Map<SensorType, { pad: number, decimalNumber: number }> = new Map([
  [SENSOR.DEW_POINT, { pad: 2, decimalNumber: 1 }],
  [SENSOR.WIND_DIRECTION, { pad: 3, decimalNumber: 0 }],
  [SENSOR.WIND_SPEED, { pad: 2, decimalNumber: 0 }],
  [SENSOR.WIND_SPEED_MS, { pad: 2, decimalNumber: 0 }],
  [SENSOR.TEMPERATURE, { pad: 2, decimalNumber: 1 }],
  [SENSOR.RELATIVE_HUMIDITY, { pad: 2, decimalNumber: 0 }],
  [SENSOR.PRECIPITATION, { pad: 5, decimalNumber: 2 }],
  [SENSOR.MEAN_SEA_LEVEL_PRESSURE, { pad: 6, decimalNumber: 1 }],
  [SENSOR.MOR, { pad: 4, decimalNumber: 0 }],
  [SENSOR.RUNWAY_VISUAL_RANGE, { pad: 4, decimalNumber: 0 }],
  [SENSOR.LOW_CLOUD_LAYER, { pad: 0, decimalNumber: 0 }],
  [SENSOR.MIDDLE_CLOUD_LAYER, { pad: 0, decimalNumber: 0 }],
  [SENSOR.UPPER_CLOUD_LAYER, { pad: 0, decimalNumber: 0 }],
  [SENSOR.AIRPORT_LEVEL_PRESSURE, { pad: 6, decimalNumber: 2 }],
  [SENSOR.BGL, { pad: 0, decimalNumber: 0 }],
  [SENSOR.SOLAR_RADIATION, { pad: 0, decimalNumber: 0 }],
  [SENSOR.SOLAR_DURATION, { pad: 0, decimalNumber: 1 }],
])

export function parseMeasureToStringValue(measure: Measurement | StationMeasurement | undefined, unit?: Unit) {
  if (measure) {
    let measureValue;
    if (typeof measure.value === 'string') {
      if (unit === UNIT.CROSS_WIND_COMPONENT || unit === UNIT.CODE) {
        return measure.value;
      }
      measureValue = Number.parseFloat(measure.value);
    } else {
      measureValue = measure.value;
    }
    const stringConfig = sensorTypeToRoundedConfiguration.get(measure.sensorType) ?? { pad: 4, decimalNumber: 2 };
    const multiplicator = Math.pow(10, stringConfig.decimalNumber ?? 0);
    measureValue = Math.round(measureValue * multiplicator) / multiplicator;
    return measureValue.toFixed(stringConfig.decimalNumber).toString().padStart(stringConfig.pad, '0') + ' ' + parseUnit(unit);
  }
  return '';
}

export function parseUnit(unit: Unit | undefined): string {
  switch (unit) {
    case UNIT.CELSIUS:
      return '°C';
    case UNIT.KT:
      return 'KT';
    case UNIT.METER_PER_SECOND:
      return 'm/s';
    case UNIT.H_PA:
      return 'hPa';
    case UNIT.PERCENT:
      return '%';
    case UNIT.MILIMETER:
      return 'mm';
    case UNIT.CENTIMETER:
      return 'cm';
    case UNIT.METER:
      return 'm';
    case UNIT.M_BAR:
      return 'mb';
    case UNIT.DEGREE:
      return '°';
    case UNIT.WATTS_PER_METER2:
      return 'W/m²';
    case UNIT.HOUR:
      return 'h';
    default:
      return '';
  }
}

export function parseMeasurementValueToNumberValue(value: string | number): number {
  if (typeof value === 'string') {
    return Number.parseFloat(value);
  }
  if (typeof value === 'number') {
    return value;
  }
  return NaN;
}

export function insertMeasurements(newMeasurements: Measurement[], initialMeasurements: Measurement[]): {measurements: Measurement[], inserted: boolean} {
  // Create a Map with a composite key for faster lookups
  const resultMap = new Map<string, Measurement>();
  let inserted = false;

  // Populate the Map with initial measurements
  initialMeasurements.forEach(measurement => {
    const key = `${measurement.stationId}_${measurement.sensorType}_${measurement.process}_${measurement.frequency}`;
    resultMap.set(key, measurement);
  });

  // Update or insert new measurements
  newMeasurements.forEach(newMeasurement => {
    const key = `${newMeasurement.stationId}_${newMeasurement.sensorType}_${newMeasurement.process}_${newMeasurement.frequency}`;
    const existingMeasurement = resultMap.get(key);

    // Replace the measurement if the new timestamp is greater or equal
    if (!existingMeasurement || newMeasurement.timestamp >= existingMeasurement.timestamp) {
      resultMap.set(key, { ...newMeasurement });
      inserted = true;
    }
  });

  // Convert the Map back to an array of measurements
  const resultArray = Array.from(resultMap.values());
  resultMap.clear();
  return {measurements: resultArray, inserted};
}

export const compareTimeSeriesFunction = (a: TimeSeriesItem, b: TimeSeriesItem) => a[0] - b[0];

