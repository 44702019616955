import { Injectable, inject, signal } from "@angular/core";
import { MeasurementRxStompService } from "./stomp/measurements-stomp.service";
import { HttpClient } from "@angular/common/http";
import { Measurement, MeasurementConfig, PERIOD, Process } from "../models/measurements.models";
import { Observable, bufferTime, filter, map, mergeWith, scan, shareReplay, startWith, tap } from "rxjs";
import { ObjectValues } from "../models/object-values.models";
import { API_URL } from "src/environments/url";
import { insertMeasurements } from "../utilities/measurement-utilities";
import { getConfigItemParam } from "../utilities/http-utilities";
import { SensorType, StationId } from "../models/stations.models";

type MeasurementDataStream = {
  type: 'MEASURE',
  measures: Array<Measurement>,
};

export const DEFAULT_MEASUREMENT_CONFIG: MeasurementConfig = {
  afterTimestamp: new Date(Date.now() - (1000 * 60 * 10)),
  frequency: [PERIOD.PT10M, PERIOD.PT1M],
  period: [],
  sensorType: [],
  stationId: [],
  process: [],
};

const PARAM_NAME = {
  STATION: 'stationId',
  SENSOR_TYPE: 'sensorType',
  FREQUENCY: 'frequency',
  PROCESS: 'process',
  AFTER_TIMESTAMP: 'afterTimestamp',
} as const;


@Injectable({
  providedIn: 'root',
})
export class MeasurementsService {
  http = inject(HttpClient);
  #wsService = inject(MeasurementRxStompService);
  #stationMeasurementUpdated = signal<Array<StationId>>([]);
  stationMeasurementUpdated = this.#stationMeasurementUpdated.asReadonly();

  #realTimeMeasures$ = this.#wsService.connect<MeasurementDataStream>('MEASURE').pipe(
    map(data => data.measures),
    startWith(new Array<Measurement>()),
    bufferTime(1000),
    filter(buffer => buffer.length > 0),
    map(buffer => buffer.reduce((acc, current) => {
      Array.prototype.push.apply(acc, current);
      return acc;
    }, buffer[0])),
    tap(measurements => {
      const stationIds = [...new Set(measurements.map(measurement => measurement.stationId))];
      this.#stationMeasurementUpdated.set(stationIds);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public getLastMeasurements(config: MeasurementConfig = DEFAULT_MEASUREMENT_CONFIG): Observable<Array<Measurement>> {
    const params = this.getRequestParams(config);
    return this.http.get<Array<Measurement>>(`${API_URL.MEASUREMENTS}?${params}`)
    .pipe(
      startWith(new Array<Measurement>()),
      mergeWith(this.#realTimeMeasures$),
      map(measurements => measurements.filter(measurement => filterByConfig(measurement, config))),
      scan((acc: {measurements: Array<Measurement>, inserted: boolean}, measurements: Array<Measurement>) => {
        return insertMeasurements(measurements, acc.measurements);
      }, {measurements: new Array<Measurement>(), inserted: true}),
      filter(acc => acc.inserted),
      map(acc => acc.measurements),
    );
  }

  public getMeasurements(config: MeasurementConfig = DEFAULT_MEASUREMENT_CONFIG): Observable<Array<Measurement>> {
    const params = this.getRequestParams(config);
    return this.http.get<Array<Measurement>>(`${API_URL.MEASUREMENTS}?${params}`)
    .pipe(
      startWith(new Array<Measurement>()),
      mergeWith(this.#realTimeMeasures$),
      map(measurements => measurements.filter(measurement => filterByConfig(measurement, config))),
    );
  }

  private getRequestParams(config: MeasurementConfig = DEFAULT_MEASUREMENT_CONFIG): string {
    const afterTimestampParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, string>(PARAM_NAME.AFTER_TIMESTAMP, config.afterTimestamp?.toISOString());
    const stationIdParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, StationId>(PARAM_NAME.STATION, config.stationId);
    const sensorTypeParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, SensorType>(PARAM_NAME.SENSOR_TYPE, config.sensorType);
    const processParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, Process>(PARAM_NAME.PROCESS, config.process);
    const lastParam = true;
    const frequencyParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, string>(PARAM_NAME.FREQUENCY, config.frequency, lastParam);
    return `${afterTimestampParam}${stationIdParam}${sensorTypeParam}${processParam}${frequencyParam}`;
  }
}

function filterByConfig(measurement: Measurement, config: MeasurementConfig) {
  return (config.frequency.length === 0 || config.frequency.includes(measurement.frequency))
  && (config.process.length === 0 || config.process.includes(measurement.process))
  && (config.stationId.length === 0 || config.stationId.includes(measurement.stationId))
  && (config.sensorType.length === 0 || config.sensorType.includes(measurement.sensorType));
}